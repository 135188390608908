@import "fonts";

$text-shadow: 3px 2px 15px rgba(0, 0, 0, 0.6);

@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }

  @media screen and (device-width: 390px)
  and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin iPadPro {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin notebook {
  @media screen and (max-width: 1281px) {
    @content;
  }
}

@mixin text {
  line-height: 1.3;
  font-size: 24px;
  font-weight: 300;

  @include mobile {
    font-size: 18px;
  }
}