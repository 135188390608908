@import "src/styles/utils";

.slider {
  display: flex;
  flex-wrap: wrap;
  height: 200px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;

  img {
    object-fit: contain;
    width: 100px;
    height: 100px;
  }
}

@include iPadPro {
  .slider {
    height: auto;
    max-width: 100%;
  }
}

@include mobile {
  .slider {
    height: auto;
    max-width: 100%;
  }

  .item {
    width: 70px;
    height: 70px;

    img {
      width: 70px;
      height: 70px;
    }
  }
}
