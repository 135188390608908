@import "src/styles/utils";

.header {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1240px;
  width: calc(100% - 122px);
  margin: 0 auto;
  padding: 0 10px;
  height: 66px;
  color: #fff;
  transition: all 100ms;
  z-index: 2;

  animation-name: appear;
  animation-duration: 500ms;

  &.white {
    .link img {
      filter: brightness(1) invert(1);
    }
  }
}

.controls {
  display: flex;
  column-gap: 10px;
}

.toggle {
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 1px solid transparent;
  border-radius: 50%;
  transition: border-color 100ms ease-in;
  cursor: pointer;

  &:hover {
    border-color: #fff;
  }

  div {
    position: relative;
    display: block;
    width: 16px;
    height: 2px;
    background-color: #fff;
    border-radius: 5px;

    &::before {
      position: absolute;
      top: -6px;
      content: "";
      display: block;
      width: 16px;
      height: 2px;
      background-color: #fff;
      border-radius: 5px;
    }

    &::after {
      position: absolute;
      bottom: -6px;
      content: "";
      display: block;
      width: 16px;
      height: 2px;
      background-color: #fff;
      border-radius: 5px;
    }
  }

  &.white {
    &:hover {
      border-color: #000;
    }

    div,
    div::before,
    div::after {
      background-color: #000;
    }
  }
}

@keyframes appear {
  0% {
    transform: translate(-50%, -100px);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

@include mobile {
  .header {

    max-width: 100vw;
    width: calc(100% - 70px);
  }
}
