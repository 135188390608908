@import "src/styles/utils";

.tabs {
  min-width: 245px;
  align-self: center;
}

.tab {
  height: 60px;
  margin-bottom: 18px;
  padding-left: 40px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  transition: background-color 300ms linear;
  cursor: pointer;

  &.active {
    font-weight: 900;
    color: #000;
    background-color: #fff;
  }

  &:hover {
    background-color: #fff;
    color: #000;
  }
}

@include iPadPro {
  .tabs {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    max-width: 100vw;
    min-height: 60px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tab {
    height: 50px;
    padding: 0 20px;
    line-height: 50px;
    font-size: 20px;
    border-radius: 30px;
    width: max-content;
    white-space: nowrap;
  }

}

@include mobile {
  .tabs {
    padding: 0 18px;
    column-gap: 8px;
    min-height: 70px;
    margin-bottom: 15px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: hidden;
  }

  .tab {
    font-size: 14px;
    border: 1px solid #fff;
    font-weight: 300;
    height: 26px;
    line-height: 26px;
    margin-bottom: 0;

    &.active {
      font-weight: 500;
    }
  }
}
