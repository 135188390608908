@import "src/styles/utils";

.link {
  margin-top: 50px;
}

@include iPadPro {
  .link {
    margin: 30px auto;
  }
}
