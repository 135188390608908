.pipe__img {
    width: 70vw;
}

.fixedPipe {
    &_wrapper {
        position: fixed;
        z-index: 99;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &_text {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        // top: 50%;
        // left: 50%;
        background-color: #636262;
        color: transparent;
        text-shadow: 0 1px 1px #b3b3b3;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        user-select: none;

        font-family: "Eduardian", serif;
        font-size: 3.5vw;
        line-height: 30px;
    }
}

.image__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

input {
    max-width: 53vw;
    border: none;
    outline: none;
    text-align: center;
    background-color: transparent;
    text-shadow: 0 1px 1px #b3b3b3;
    color: #b3b3b3;
    caret-color: #000;
}

.confirm {
    width: 15.6vw;
    height: 6.5vw;
    background-color: #23b6e4;
    color: #fff;
    font-size: 3.2vw;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 500;
}

.settings {
    display: flex;
    column-gap: 15px;
    margin-bottom: 62px;

    &:first-child {
        margin-bottom: 46px;
    }
}

.item {
    height: 26px;
    padding: 0 17px;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 15px;

    &:hover,
    &.active {
        border-color: #fff;
    }
}
