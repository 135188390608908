.wrapper {
  position: fixed;
  top: 0;
  left: -35px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  animation-name: appear;
  animation-duration: 300ms;
}

.header {
  padding: 40px 20px;
  width: 100%;
  text-align: center;
  background-color: #fff;
}

.link {
  display: inline-block;

  padding: 10px 20px;
  text-decoration: none;
  color: #000;

  & img {
    margin-right: 10px;
    filter: brightness(1) invert(1);
  }
}

.nav::after {
  content: "";
  display: block;
  width: 50%;
  height: 1px;
  margin: 10px auto;
  background-color: #000;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
}

@keyframes appear  {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
