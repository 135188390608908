@import "src/styles/utils";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
  max-width: 1080px;
  width: 100%;
  height: 195px;
  margin: 0 auto;
  padding: 20px 20px 0;
}

.copyright {
  font-size: 10px;

  img {
    margin-bottom: 20px;
  }
}

.link {
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.social {
  display: block;
  padding: 8px 0;
  transition: opacity 100ms ease-in;

  &:hover {
    opacity: 0.7;
  }
}

@include mobile {
  .footer {
    flex-wrap: wrap;
    height: auto;
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    order: 1;
  }

  .block {
    flex-basis: 45%;
  }

  .socials {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 20px 0;
  }
}
