@import "src/styles/utils";

html {
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  background: #000;
  overflow-x: hidden;
  color: #fff;
}

.container {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  overflow-x: hidden;
}

.App {
  position: relative;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
}

p {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: 0;
}

button,
input,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

*::placeholder {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 0.8px;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  padding: 0;
  list-style: none;
}

.keen-slider {
  max-width: 100vw;
}

@include mobile {
  .container {
    width: 100%;
    padding: 0 20px;
  }
}