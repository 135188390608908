@import "src/styles/utils";

.wrapper {
  position: relative;
}

.settings {
  position: sticky;
  top: 66px;
  display: flex;
  height: 100vh;
}

.title {
  margin-bottom: 65px;
}

.content {
  max-width: 400px;
  display: grid;
  grid-template-rows: 1fr 5fr 1fr;
  margin: 5vw 0 5vw 5vw;
}

.price {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}

.colors {
  display: flex;
  object-fit: contain;
  height: 100vh;
  width: auto;
  padding-right: 10vw;

  img {
    object-fit: contain;
  }
}

.design {
  object-fit: contain;
  max-width: 50%;
  height: auto;

  img {
    width: 100%;
    object-fit: contain;
  }
}

.engraving {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  p {
    background-color: #636262;
    color: transparent;
    text-shadow: 0 1px 1px #b3b3b3;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    user-select: none;

    font-family: "Eduardian", serif;
    font-size: 30px;
    line-height: 30px;
  }

  img {
    width: 100%;
  }

  input {
    width: 1000px;
    font-size: 2.6vw;
  }
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  pointer-events: none;
}

.mask {
  background-position: -10% center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
}

.img {
  display: flex;
  object-fit: contain;
  height: 100vh;
  width: auto;
  padding-right: 10vw;

  img {
    width: 100%;
    height: 100%;
  }
}

@include notebook {
  .title {
    margin-bottom: 20px;
  }

  .settings {
    top: 0;
  }

  .content {
    display: block;
  }

  .colors {
    height: 70vh;
    margin: auto;
  }

  .design {
    margin: auto;
  }

  .img {
    height: 70vh;
    margin: auto;
  }

  .engraving {
    width: 35vw;
  }
}

@include iPadPro {
  .title {
    text-align: center;
  }

  .content {
    display: grid;
    flex-grow: 1;
    grid-template-rows: auto;
    max-width: 60vw;
    margin: 30px 0 30px 30px;
  }

  .settings {
    top: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 80vh;
  }

  .design {
    img {
      height: 350px;
    }
  }

  .colors {
    height: auto;

    img {
      height: 350px;
    }
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }

  .img {
    height: 20vh;
  }

  .engraving {
    max-width: 400px;
    width: 100%;

    p {
      font-size: 20px;
      line-height: 20px;
    }

    img {
      width: 100%;
    }
  }
}

@include mobile {
  .action {
    justify-content: center;
    column-gap: 26px;
    margin-top: 20px;
  }

  .settings {
    padding: 0 10px;
  }

  .title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    margin: 0 auto;
  }

  .design {
    max-width: 100%;
  }

  .engraving {
    p {
      font-size: 20px;
      line-height: 20px;
    }
  }

  .link {
    justify-content: center;
    margin: 90px 0 0;
  }

  .colors {
    padding-right: 25vw;

    img {
      height: 300px;
    }
  }

  .price {
    margin-bottom: 0;
    font-size: 24px;
  }

  .img {
    height: 40vh;
    padding-left: 10vw;
    padding-right: 0;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (min-width: 1400px) {
  .wrapper {
    padding-bottom: 20vh;
  }
}
